<template>
  <div>
    <v-tooltip bottom color="#311B92">
      <template v-slot:activator="{ on, attrs }">
        <div
          class="block-icon mr-2"
          v-on="on"
          v-bind="attrs"
          @click.prevent.stop="handleCheckDossier"
        >
          <font-awesome-icon icon="list" />
        </div>
      </template>
      <span>Liste des dossiers</span>
    </v-tooltip>
    <v-dialog
      v-model="ListDossier"
      persistent
      max-width="1500px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-list"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Confirmation des dossiers </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalList('hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <v-row>
            <!-- <v-col cols="6">
              <div>
                <div class="d-flex justify-content-start text-dark mt-1">
                  <p><b>Liste des dossiers confirmée :</b></p>
                </div>
                <div
                  v-if="computedListDossierConfirme.length == 0"
                  class="justify-content-center"
                >
                  Aucun dossier
                </div>
                <template>
                  <div class="d-flex justify-content-between">
                    <v-row>
                      <v-col cols="6" >
                        <v-switch
                      v-model="checkAllConfirme"
                      @change="changeAllDossierConfirme"
                      :label="`Tous`"
                      color="#704ad1"
                    ></v-switch>
                      </v-col>
                      <v-col cols="5" class="mt-3">
                        <v-text-field
                      label="Rechercher"
                      mess
                      :persistent-placeholder="true"
                      v-model="searchConfirme"
                      outlined
                      color="#704ad1"
                    >
                    </v-text-field>
                      </v-col>
                      <v-col cols="1"></v-col>
                    </v-row>
                 
                  
                  </div>

                  <br />
                  <div class="custom-class-fields-av scroll-specifics-params">
                    <v-checkbox
                      v-for="(option, index) in computedListDossierConfirme"
                      :key="
                        option.confirmations == 0
                          ? index + 'deconf' + option.id
                          : index + 'conf' + option.is
                      "
                      v-model="selectedConfirme"
                      :value="option"
                      :label="option.numero_dossier"
                      color="#704ad1"
                    ></v-checkbox>
                  </div>
                </template>
              </div>
            </v-col> -->
            <v-col cols="6">
              <div>
                <div class="d-flex justify-content-start text-dark mt-1">
                  <p><b>Liste des dossiers confirmée :</b></p>
                </div>
                <div
                  v-if="computedListDossierConfirme.length == 0"
                  class="justify-content-center"
                >
                  Aucun dossier
                </div>
                <template>
                  <div class="d-flex justify-content-between">
                    <v-row>
                      <v-col cols="6">
                        <v-switch
                          v-model="checkAllConfirme"
                          @change="changeAllDossierConfirme"
                          :label="`Tous`"
                          color="#704ad1"
                        ></v-switch>
                      </v-col>
                      <v-col cols="5" class="mt-3">
                        <v-text-field
                          label="Rechercher"
                          mess
                          :persistent-placeholder="true"
                          v-model="searchConfirme"
                          outlined
                          color="#704ad1"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="1"></v-col>
                    </v-row>
                  </div>

                  <br />
                  <div class="custom-class-fields-av scroll-specifics-params">
                    <v-checkbox
                      v-for="(option, index) in computedListDossierConfirme"
                      :key="
                        option.confirmations == 0
                          ? index + 'deconf' + option.id
                          : index + 'conf' + option.is
                      "
                      v-model="selectedConfirme"
                      :value="option"
                      :label="option.numero_dossier"
                      color="#704ad1"
                    ></v-checkbox>
                  </div>
                </template>
              </div>
            </v-col>
            <v-col cols="6">
              <div>
                <div class="d-flex justify-content-start text-dark mt-1">
                  <p><b>Liste des dossiers non confirmée :</b></p>
                </div>
                <div
                  v-if="computedListDossierNonConfirme.length == 0"
                  class="justify-content-center"
                >
                  Aucun dossier
                </div>
                <template>
                  <div class="d-flex justify-content-between">
                    <v-row>
                      <v-col cols="6">
                        <v-switch
                          v-model="checkAllNonConfirme"
                          @change="changeAllDossierNonConfirme"
                          :label="`Tous`"
                          color="#704ad1"
                        ></v-switch>
                      </v-col>
                      <v-col cols="5" class="mt-3">
                        <v-text-field
                          label="Rechercher"
                          mess
                          :persistent-placeholder="true"
                          v-model="searchNonConfirme"
                          outlined
                          color="#704ad1"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="1"></v-col>
                    </v-row>
                  </div>

                  <br />
                  <div class="custom-class-fields-av scroll-specifics-params">
                    <v-checkbox
                      v-for="(option, index) in computedListDossierNonConfirme"
                      :key="
                        option.confirmations == 0
                          ? index + 'deconf' + option.id
                          : index + 'conf' + option.is
                      "
                      v-model="selectedNonConfirme"
                      :value="option"
                      :label="option.numero_dossier"
                      color="#704ad1"
                    ></v-checkbox>
                  </div>
                </template>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-row>
            <v-col cols="2">
              <v-btn
                color="#704ad1"
                text
                @click="changeConfirmer(1)"
                :loading="confirm1"
                :disabled="confirm1"
                :class="{ loader: confirm1 }"
              >
                Déconfirmée
                <font-awesome-icon icon="angle-right" />
              </v-btn>
            </v-col>
            <v-col cols="8"></v-col>
            <v-col cols="2">
              <v-btn
                color="#704ad1"
                text
                @click="changeConfirmer(2)"
                :loading="confirm2"
                :disabled="confirm2"
                :class="{ loader: confirm2 }"
              >
                Confirmée
                <font-awesome-icon icon="angle-left" />
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: ['dataToUse'],
  data() {
    return {
      searchConfirme: null,
      searchNonConfirme: null,
      checkAllConfirme: false,
      checkAllNonConfirme: false,
      selectedConfirme: [],
      selectedNonConfirme: [],
      ListDossier: false,
      confirm1: false,
      confirm2: false
    };
  },
  methods: {
    ...mapActions(['confirmationMultipleDossierSimulation']),
    handleModalList(action) {
      this.ListDossier = action == 'show' ? true : false;
    },
    handleCheckDossier() {
      this.handleModalList('show');
      this.selectedConfirme = [];
      this.selectedNonConfirme = [];
      this.dataToUse.forEach(element => {
        if (element.project_confirmation === true) {
          this.selectedConfirme.push({
            id: element.project_id,
            numero_dossier: element.project_numero,
            confirmations: 1
          });
        } else {
          this.selectedNonConfirme.push({
            id: element.project_id,
            numero_dossier: element.project_numero,
            confirmations: 0
          });
        }
      });
    },
    changeAllDossierConfirme() {
      this.selectedConfirme = this.checkAllConfirme
        ? this.computedListDossierConfirme.slice()
        : [];
    },
    changeAllDossierNonConfirme() {
      this.selectedNonConfirme = this.checkAllNonConfirme
        ? this.computedListDossierNonConfirme.slice()
        : [];
    },
    async changeConfirmer(e) {
      var bodyFormData = new FormData();
      if (e == 1) {
        this.confirm1 = true;
        for (let j = 0; j < this.selectedConfirme.length; j++) {
          bodyFormData.append('ids[' + [j] + ']', this.selectedConfirme[j].id);
        }
      } else {
        this.confirm2 = true;
        for (let j = 0; j < this.selectedNonConfirme.length; j++) {
          bodyFormData.append(
            'ids[' + [j] + ']',
            this.selectedNonConfirme[j].id
          );
        }
      }

      let testValue = null;
      for (var pair of bodyFormData.entries()) {
        if (pair[0] && pair[0].includes('ids')) {
          testValue = true;
        }
      }
      if (testValue == null) {
        this.error =
          "Sélectionne au moins un dossier afin d'effectuer un changement de l'etat.";
        this.$alert('', this.error, 'error').then(() => {
          this.error = null;
        });
        this.confirm1 = false;
        this.confirm2 = false;

        return;
      } else {
        const response = await this.confirmationMultipleDossierSimulation(
          bodyFormData
        );
        if (response && response.succes == true) {
          this.confirm1 = false;
          this.confirm2 = false;
          response.data.forEach(element => {
            let index = this.getSimulationFactureFraisDataDossier.findIndex(
              i => i.id == element.id
            );
            if (index >= 0) {
              this.getSimulationFactureFraisDataDossier[index].confirmations =
                element.confirmations == true ? 1 : 0;
            }
            let indexes = [];
            for (
              let indexP = 0;
              indexP < this.getSimulationFactureFraisData.length;
              indexP++
            ) {
              if (
                this.getSimulationFactureFraisData[indexP].project_id ==
                element.id
              ) {
                indexes.push(indexP);
              }
            }

            indexes.forEach(element1 => {
              this.getSimulationFactureFraisData[
                element1
              ].project_confirmation = element.confirmations;
            });
          });
          this.selectedConfirme = [];
          this.selectedNonConfirme = [];
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'getSimulationFactureFraisDataDossier',
      'getSimulationFactureFraisData'
    ]),
    computedListDossierConfirme() {
      if (this.searchConfirme != null) {
        return this.getSimulationFactureFraisDataDossier.filter(
          item =>
            item.numero_dossier
              .toLowerCase()
              .includes(this.searchConfirme.toLowerCase()) &&
            item.confirmations == 1
        );
      }
      return this.getSimulationFactureFraisDataDossier.filter(
        item => item.confirmations == 1
      );
    },
    computedListDossierNonConfirme() {
      if (this.searchNonConfirme != null) {
        return this.getSimulationFactureFraisDataDossier.filter(
          item =>
            item.numero_dossier
              .toLowerCase()
              .includes(this.searchNonConfirme.toLowerCase()) &&
            item.confirmations == 0
        );
      }
      return this.getSimulationFactureFraisDataDossier.filter(
        item => item.confirmations == 0
      );
    }
  }
};
</script>

<style lang="scss">
.mt-14 {
  margin-top: 14px !important ;
}
.custom-class-fields-av,
.custom-class-fields-av-update {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-start;
  overflow-x: auto;
  text-align-last: left;
  max-height: calc(100% - 90px);
}

.custom-class-fields-av {
  .custom-control {
    width: 30%;
  }
}
.scroll-specifics-params {
  height: calc(100vh - 266px);
  overflow-y: auto;
  align-content: start;
  align-items: flex-start;
}
.scroll-specifics-params::-webkit-scrollbar-track {
  background: #f0eef8;
  border-radius: 30px;
  width: 15px;
  border-right: 10px solid white;
  border-left: 10px solid white;
  border-bottom: 10px solid white;
  border-top: 10px solid white;
}
.scroll-specifics-params::-webkit-scrollbar {
  width: 30px;
  height: 30px;
}
.scroll-specifics-params::-webkit-scrollbar-thumb {
  background: #704ad1;
  border-radius: 25px;
  border: 12px solid transparent;
  background-clip: padding-box;
}
</style>
